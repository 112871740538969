import React, { useEffect, useState } from "react";
import TitleBar from "../Common/TitleBar";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemIcon, ListItemText, TextField, useMediaQuery } from "@material-ui/core";
import styled from "@emotion/styled";
import FolderIcon from "@material-ui/icons/Folder";
import ImageIcon from "@material-ui/icons/Image";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "axios";
import { name, sendMessage, socket, waitForSocketConnection } from "../../socket";

const FoldersList = ({ folders, onFolderIconClick }) => {
    const sortedFolders = folders.sort((a, b) => a.localeCompare(b));

    const isLargeScreen = useMediaQuery("(min-width:600px)");

    return (
        <Grid container spacing={2}>
            {sortedFolders.map((folder, index) => (
                <Grid item xs={isLargeScreen ? 3 : 12} key={index}>
                    <ListItem>
                        <ListItemIcon>
                            <FolderIcon style={{ fontSize: "5em", color: "#F3E5AB" }} onClick={() => onFolderIconClick(folder)} />
                        </ListItemIcon>
                        <ListItemText primary={folder} />
                    </ListItem>
                </Grid>
            ))}
        </Grid>
    );
};

const ImageList = ({ images, onArrowBack }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [fileToUpload, setFileToUpload] = useState();

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
        setFileToUpload(file);
    };

    const handleUpload = () => {
        const formData = new FormData();

        formData.append('file', fileToUpload);

        fetch(
            'http://192.168.163.30:8000/face-upload',
            {
                method: 'POST',
                body: formData,
            }
        ).then((result) => {
            console.log('Success:', result);
        });

        // window.location.reload()
    }

    return (
        <>
            <$Button size="small" variant="outlined" color="primary" onClick={() => onArrowBack(false)}>
                <ArrowBackIcon /> Back to Folders
            </$Button>
            <UploadContainer onClick={(e) => { e.stopPropagation(); document.getElementById("imageInput").click() }} >
                <InputFile
                    id="imageInput"
                    type="file"
                    accept=".jpg, .jpeg, .png, .gif"
                    onChange={handleImageChange}
                />
                {selectedImage ? (
                    <>
                        <UploadedImage
                            src={selectedImage}
                            alt="Uploaded Preview"
                        />
                        <br />

                        <Button size="small" variant="outlined" color="primary" onClick={(e) => { e.stopPropagation(); handleUpload() }}>Upload</Button>
                    </>
                ) : (
                    <p>Click to upload an image</p>
                )}
            </UploadContainer>

            <List>
                {images.map((image, index) => (
                    <ListItem key={index}>
                        <ListItemIcon>
                            <ImageIcon />
                        </ListItemIcon>
                        <ListItemText primary={image} />
                    </ListItem>
                ))}
            </List>
        </>
    );
}

const FaceRecognition = () => {
    const [trainMsg, setTrainMsg] = useState("");
    const [folders, setFolders] = useState([]);
    const [isAddFolder, setIsAddFolder] = useState(false);
    const [folderName, setFolderName] = useState('');
    const [isViewFolder, setIsViewFolder] = useState(false);

    useEffect(() => {
        socket.onmessage = (message) => {
          let obj = JSON.parse(message.data);
          if ((message.data) && (message.data.toString().includes("face_recog"))) {
            setTrainMsg('Training done!')
        }
        }

        onFetchData()
        
      }, []);

    const onFetchData = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://cloud-api-389807.ue.r.appspot.com/face-folders?robot=' + localStorage.getItem('remoteId') + '&username=' + localStorage.getItem("selected"),
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log("SERVICE FACE DATA", response.data.data.folders);
                setFolders(response.data.data.folders)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onClickAddFolder = () => {
        setIsAddFolder(true);
        setFolderName('');
    };

    const onCloseFolder = () => {
        setIsAddFolder(false);
    };

    const onFolderNameChange = (e) => {
        setFolderName(e.target.value);
    };

    const onSaveFolder = () => {
        folders.push(folderName);
        console.log("Saving Access ", folders);
        const axios = require('axios');
        let data = JSON.stringify({
            "robot": localStorage.getItem('remoteId'),
            "username": localStorage.getItem('selected'),
            "folders": folders
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://cloud-api-389807.ue.r.appspot.com/face-folders',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                onCloseFolder();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onFolderIconClick = (folderName) => {
        setIsViewFolder(true);
        setFolderName(folderName);
    }

    const onClickTrain = () => {
        console.log("Train")
        setTrainMsg("Training...")
        var api = `{"api":"trFaceRecog", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        // let config = {
        //     method: 'get',
        //     url: 'http://192.168.163.30:8000/face-train',
        //     headers: {}
        // };

        // axios.request(config)
        //     .then((response) => {
        //         console.log("OKOK")
        //         console.log(JSON.stringify(response.data));
        //         setTrainMsg("Training done!")
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         setTrainMsg("Training error!")
        //     });

    }

    return (
        <>
            <TitleBar title={"Face Recognition"} parentNode={"/technology-tools"} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {!isViewFolder && (
                    <$Button size="small" variant="outlined" color="primary" onClick={onClickAddFolder}>
                        Add New Folder
                    </$Button>
                )}
                <$Button size="small" variant="outlined" color="primary" onClick={onClickTrain}>
                    Train
                </$Button>
                <>{trainMsg}</>
            </div>

            {!isViewFolder && <FoldersList folders={folders} onFolderIconClick={onFolderIconClick} />}

            {isViewFolder && <ImageList images={['Image 1']} onArrowBack={setIsViewFolder} />}

            <Dialog open={isAddFolder} onClose={onCloseFolder} fullWidth>
                <DialogTitle id="form-dialog-title" onClose={onCloseFolder}>Create New Folder</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        label="Folder Name"
                        value={folderName}
                        onChange={onFolderNameChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseFolder} color="primary">Cancel</Button>
                    <Button onClick={onSaveFolder} color="primary">Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const $Button = styled(Button)`
    width: 150px;
`;

const UploadContainer = styled("div")({
    border: "2px dashed #ccc",
    borderRadius: "8px",
    padding: "16px",
    textAlign: "center",
    cursor: "pointer",
    marginBottom: "16px",
    paddingTop: "10px",
    marginTop: "10px"
});

const InputFile = styled("input")({
    display: "none",
});

const UploadedImage = styled("img")({
    maxWidth: "100%",
    maxHeight: "200px",
    margin: "16px 0",
});

export default FaceRecognition;
