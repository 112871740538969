import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export default class Video extends Component {
    constructor() {
        super();
        this.state = {
            videos: []
        };
    }
    async componentDidMount() {
        console.log('Joanna is here')
        try {
            const response = await fetch('http://34.92.77.213:8080/videos');
            const data = await response.json();
            this.setState({ videos: [...data] });
            console.log(data)
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        return (
            <div className="App App-header">
                <div className="container">
                    <div className="row">
                        {this.state.videos.map(video =>
                        <div className="col-md-4" key={video.filename}>
                            <Link to={`/player/${video.filename}`}>
                                <div className="card border-0">
                                    {/* <img src={`http://localhost:8080${video.poster}`} alt={video.name} /> */}
                                    <div className="card-body">
                                        <p>{video.filename}</p>
                                        <p>{video.time}</p>
                                        <p>{video.robotid}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}