import React, { Component, useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Redirect } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles, ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Select from '@material-ui/core/Select';
import TitleBar from '../Common/TitleBar';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import "../../App.css"

import { sendMessage, socket, waitForSocketConnection, name } from '../../socket';
import TranslationService from "../../services/translation.service";

require("react-big-calendar/lib/css/react-big-calendar.css");

const localizer = momentLocalizer(moment)
const theme = createTheme();

const styles = (mtheme) => ({
  formControl: {
    margin: mtheme.spacing(0),
    minWidth: 250,
  },
});

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

// const CustomToolbar = (props) => {
//   const [viewState, setViewState] = useState('month');

//   const goToDayView = () => {
//     props.onView('day');
//     setViewState('day');
//   };
//   const goToWeekView = () => {
//     props.onView('week');
//     setViewState('week');
//   };
//   const goToMonthView = () => {
//     props.onView('month');
//     setViewState('month');
//   };

//   const goToBack = () => {
//     if (viewState === 'month') {
//       props.date.setMonth(props.date.getMonth() - 1);
//     } else if (viewState === 'week') {
//       props.date.setDate(props.date.getDate() - 7);
//     } else {
//       props.date.setDate(props.date.getDate() - 1);
//     }
//     props.onNavigate('prev');
//   };

//   const goToNext = () => {
//     if (viewState === 'month') {
//       props.date.setMonth(props.date.getMonth() + 1);
//     } else if (viewState === 'week') {
//       props.date.setDate(props.date.getDate() + 7);
//     } else {
//       props.date.setDate(props.date.getDate() + 1);
//     }
//     props.onNavigate('next');
//   };

//   const goToToday = () => {
//     const now = new Date();
//     props.date.setMonth(now.getMonth());
//     props.date.setYear(now.getFullYear());
//     props.date.setDate(now.getDate());
//     props.onNavigate('current');
//   };

//   useEffect(() => {
//     //console.log(props);
//   }, []);

//   return (
//     <div>
//       {/* <label>{moment(props.date).format('DD/MM/YYYY')}</label> */}

//       <div>
//         {/* <button onClick={goToBack}>&#8249;</button>
//         <button onClick={goToToday}>today</button>
//         <button onClick={goToNext}>&#8250;</button> */}
//         {/* <button onClick={goToMonthView}>month</button> */}
//         {/* <button onClick={goToWeekView}>week</button>
//         <button onClick={goToDayView}>day</button> */}
//       </div>
//     </div>
//   );
// };

class MyCalendar extends Component {
  constructor() {
    super();
    this.state = {
      events: [],
      viewEvents: [],
      syncedSong: false,
      songs: [],
      books: [],
      youtubes: [],
      _id: "",
      title: "",
      start: "",
      end: "",
      desc: "",
      service: "",
      content: "",
      contentlist: [],
      repeating: 1,
      openSlot: false,
      openEvent: false,
      startOfWeek: "",
      endOfWeek: "",
      clickedEvent: {},
      duration: 5,
      notes: "",
      language: ""

    };
    this.handleClose = this.handleClose.bind(this);
    this.computeTitle = this.computeTitle.bind(this);
  }




  componentDidMount() {
    console.log("MYCALENDAR LOADING")
    let name2 = localStorage.getItem('remoteId');
    //if(!name2){
    //  return <Redirect to={{pathname: '/home'}} push={true}/>
    // }
    var startOfWeek = moment().startOf('week').toDate();
    var endOfWeek = moment().endOf('week').toDate();
    //alert(startOfWeek +' to '+endOfWeek);
    const name = localStorage.getItem('remoteId');
    //this.getCachedEvents();
    var request = `{"action":"getMyCalender","name":"${name}"}`

    waitForSocketConnection(socket, () => sendMessage(request))

    // var request1 = `{"action":"getWorkFlowList","name":"${name}"}`
    // waitForSocketConnection(socket, () => sendMessage(request1))

    socket.onmessage = (message) => {
      console.log("CALENAR MESSAGE")
      console.log(message.data.toString(), "DATA TO STRING")
      if (message.data.toString().includes("workflows")) {
        //console.log(message.data.toString())
        console.log('mylist', message.data)
        try {
          var mylist = []
          console.log('mylist')
          JSON.parse(message.data).workflows.map((item, i) => {
            mylist.push({
              value: item,
              label: item,
            });
          });
          console.log(mylist)
          this.setState({ contentlist: mylist });
        }

        catch (e) {
          console.log('Something is Wrong')
        }
      }
      else if ((message.data) && (message.data.toString().includes("calendar"))) {
        console.log("CALENDAR DATE ", message.data)
        const cal_events = (JSON.parse(message.data).calendar);
        this.setState({ events: this.calendarEvents(cal_events) })
      }
      else if (message.data.toString().includes("songs")) {
        var str = message.data.substring(0, message.data.length - 1);
        console.log(str)
        try {
          var obj = JSON.parse(str.replace(/\0/g, ''));
          // if (obj.type === "songs")
          // {
          this.setState({ 'syncedSong': true, 'songs': obj.songlist });

          console.log("SAVED SONGS");
          let name = localStorage.getItem('remoteId');
          var api = `{"api":"book", "action":"get","name": "${name}"}`;
          waitForSocketConnection(socket, () => sendMessage(api))
          // }
        }
        catch (e) {
          console.log("SAVING SONG ERROR ", e)
        }
      }
      else if (message.data.toString().includes("technology_support")) {
        var str = message.data.substring(0, message.data.length - 1);
        console.log(message.data, "DATA")
        try {
          var mylist = []
          JSON.parse(message.data).object_data.map((item, i) => {
            mylist.push({
              value: item.url,
              label: item.title,
            });
          });

          this.setState({ contentlist: mylist });
        }
        catch (e) {
          console.log("Found Error: ", e);
        }
      }
      else if (message.data.toString().includes("youtube")) {
        var str = message.data.substring(0, message.data.length - 1);
        console.log(message.data, "DATA")
        try {
          var mylist = []
          JSON.parse(message.data).object_data.map((item, i) => {
            mylist.push({
              value: item.url,
              label: item.title,
            });
          });

          this.setState({ contentlist: mylist });
        }
        catch (e) {
          console.log("Found Error: ", e);
        }
      }
      else if (message.data.toString().includes("books")) {
        var str = message.data.substring(0, message.data.length);
        console.log(str)
        try {
          var obj = JSON.parse(str.replace(/\0/g, ''));
          if (obj.type === "books") {

            var booklist = [];
            var paths = obj.booklist;
            //console.log(obj.booklist);
            paths.forEach(function (path) {
              // console.log(path["path"]);
              booklist.push({ 'path': path["path"], 'title': path["path"].replace(/^.*[\\\/]/, '') });

            });
            this.setState({ 'books': booklist });
          }
        }
        catch (e) {

        }
      }
      else if (message.data.toString().includes("learning")) {
        console.log(message.data.toString())
        try {
          var mylist = []
          JSON.parse(message.data).learning.map((item, i) => {
            mylist.push({
              value: item,
              label: item,
            });
          });

          this.setState({ contentlist: mylist });
        }

        catch (e) {

        }
      }
      else if (message.data.toString().includes("quiz")) {
        console.log(message.data.toString())
        try {
          var mylist = []
          JSON.parse(message.data).quiz.map((item, i) => {
            mylist.push({
              value: item,
              label: item,
            });
          });

          this.setState({ contentlist: mylist });
        }

        catch (e) {

        }
      }
      else if (message.data.toString().includes("bingo")) {
        console.log(message.data.toString())
        try {
          var mylist = []
          JSON.parse(message.data).bingo.map((item, i) => {
            mylist.push({
              value: item,
              label: item,
            });
          });

          this.setState({ contentlist: mylist });
        }

        catch (e) {

        }
      }

    }

    if (!this.state.syncedSong) {
      let name = localStorage.getItem('remoteId');
      var api = `{"api":"music", "action":"get","name": "${name}"}`;
      waitForSocketConnection(socket, () => sendMessage(api))
      //sendMessage(api); 
    }

  }

  //  UserService.getMyCalendar().then(
  //   (response) => {
  //     //console.log(response["calendar"]);
  //     const cal_events = response["calendar"];

  //     this.setState({events: this.calendarEvents(cal_events)})



  //   },
  //   (error) => {
  //     const _content = 
  //       (error.response && error.response) || 
  //       error.message ||
  //       error.toString();

  //   });






  // socket.onmessage = (message) => {
  //   var str = message.data.substring(0,message.data.length-1);
  //   //console.log(str);
  //   try {
  //     var obj = JSON.parse(str.replace(/\0/g, ''));
  //     if (obj.type === "songs")
  //     {
  //       this.setState({'syncedSong':true,'songs':obj.songlist});

  //       //console.log(obj.songlist);
  //       api = `{"api":"book", "action":"get"}`
  //       sendMessage(api); 
  //     }

  //     else if (obj.type === "books")
  //     {

  //       var booklist = [];
  //       var paths = obj.booklist;
  //       //console.log(obj.booklist);
  //       paths.forEach(function(path) {
  //         // console.log(path["path"]);
  //         booklist.push({'path':path["path"], 'title':path["path"].replace(/^.*[\\\/]/, '')});

  //       });
  //       this.setState({'books':booklist});
  //     }


  //   } catch (e) {

  //   }
  // };



  calendarEvents(cal_events) {
    var calEvents = []

    var firstday = moment().startOf('week').toDate();
    var lastday = moment().endOf('week').toDate();

    // alert(firstday + ' '+lastday);
    this.setState({
      'firstday': firstday,
      'lastday': lastday
    });


    cal_events.map((event, index) => {
      var daytime = event.time.split(' ');
      if (event.time.split('T').length == 2) {
        daytime = event.time.split('T');
        event.time = event.time.replace("T", " ")
      }
      //console.log(event);
      //console.log(daytime[0]+'&'+daytime[1]);
      var phrases = event.phrase.split('@');

      //alert(phrase[1] +" params: "+phrase[2]);
      console.log('date of day: ' + this.dateofday(daytime[0], daytime[1]));
      var daytime = event.time.split(' ');
      const revent = new Object();
      revent.title = phrases[0].substring(1);
      revent.start = new Date(this.dateofday(daytime[0], daytime[1]));
      if (daytime.length > 2) {
        revent.end = new Date(this.dateofday(daytime[0], daytime[2]));
        //console.log("===end time "+daytime[2])
      } else {
        revent.end = new Date(moment(revent.start).add(60, 'm').toDate());
      }


      // revent.repeating = 1;
      revent._id = index;
      if (phrases.length > 1) {

        var funcs = phrases[1].slice(0, -1).match(/(\w+)\("(.*)"\)/);
        revent.service = funcs[1];
        revent.content = funcs[2];
        //alert(funcs[1] +" params: "+funcs[2]);
      } else {
        revent.service = "";
        revent.content = "";
      }
      revent.desc = ' ';
      revent.duration = event.duration.replace("\r", "")
      revent.repeating = event.repeating.replace("\r", "")
      revent.notes = event.notes.replace("\r", "")


      calEvents.push(revent);
      //console.log(`event====${revent.content}=====${revent.service}`);

    });

    return calEvents;
  }

  dateofday = (inday, intime) => {
    var hours = intime.split(':');
    var res = Date();
    // console.log(this.state.firstday + '==== ' + this.state.lastday);

    var start = moment.utc(this.state.firstday).toDate();
    var end = moment.utc(this.state.lastday).toDate();
    console.log(this.state.firstday, "FIRT")


    var loop = moment.utc(start).toDate();
    while (loop <= end) {
      console.log("loop getday", loop.getDate())
      if ('' + loop.getDay() == inday) {
        res = moment.utc(loop.setHours(hours[0], hours[1], 0)).toDate();
        // alert(res);

      }
      if (inday.split("-").length == 3) {
        intime = intime.split(".")[0]
        res = new Date(Date.parse(inday + "T" + intime + "+00:00"));
        console.log("RES HEHE ", res, inday + "T" + intime)
      }
      var newDate = loop.setDate(loop.getDate() + 1);
      loop = moment.utc(newDate).toDate();
    }



    return new Date(res);
  }

  //closes modals
  handleClose() {
    this.setState({ openEvent: false, openSlot: false });
  }

  //  Allows user to click on calendar slot and handles if appointment exists
  handleSlotSelected(slotInfo) {
    //console.log("Real slotInfo", slotInfo);
    this.setState({
      title: "",
      desc: "",
      repeating: 1,
      start: slotInfo.start,
      end: slotInfo.end,
      openSlot: true,
      service: "",
      content: "",
      duration: 5,
      notes: ""


    });
  }

  handleChange = (e) => {
    console.log("SELECTED ", e.target.value)
    const value = e.target.value;
    const name = localStorage.getItem('remoteId');
    // alert(value);
    let mylist = [];
    this.setState({ 'service': value });
    if (value == 'Learning') {

      var api = `{"action":"getLearningList","name":"${name}"}`;
      waitForSocketConnection(socket, () => sendMessage(api))
    }

    if (value == 'Quiz') {


      var api = `{"action":"getQuizList","name":"${name}"}`;
      waitForSocketConnection(socket, () => sendMessage(api));
      // AuthService.getQuizList().then(
      //   (response) => {

      //     response.data.quiz.map((item,i) => {
      //         mylist.push({                
      //           value: item,
      //           label: item,
      //         });
      //     });

      //     this.setState({contentlist:mylist});
      //     //console.log(mylist);
      //   },
      //   (error) => {

      //   }
      // )
    }

    if (value == 'Bingo') {

      var api = `{"action":"getBingoList","name":"${name}"}`;
      waitForSocketConnection(socket, () => sendMessage(api));
    }

    if (value == 'Game') {

      var api = `{"action":"getBingoList","name":"${name}"}`;
      waitForSocketConnection(socket, () => sendMessage(api));
    }

    if (value == 'TVChannel') {
      var listing = [];
      for (let i = 1; i <= 100; i++) {
        listing.push({
          value: i,
          label: `Channel ${i}`, // You can customize this label as needed
        });
      }
      console.log(listing);
      this.setState({ contentlist: listing });      
    }


    if (value == 'Conversation') {
      var api = `{"action":"getWorkFlowList","name":"${name}"}`;
      waitForSocketConnection(socket, () => sendMessage(api));
    }

    if (value == 'YouTube') {
      var api = `{"action":"getYouTubeList","name":"${name}"}`;
      waitForSocketConnection(socket, () => sendMessage(api));
    }

    if (value == 'Videos') {
      var api = `{"action":"getYouTubeList","name":"${name}"}`;
      waitForSocketConnection(socket, () => sendMessage(api));
    }

    if (value == 'TechnologySupport') {
      var api = `{"action":"getTechnologySupport","name":"${name}"}`;
      waitForSocketConnection(socket, () => sendMessage(api));
    }


    if (value === 'Music') {
      console.log("SELECTING MUSIC", this.state.songs)
      this.state.songs.map((item, i) => {
        mylist.push({
          value: item.path,
          label: item.title,
        });
      });

      this.setState({ contentlist: mylist });
      // console.log(mylist);
    }

    if (value === 'Book') {
      this.state.books.map((item, i) => {
        mylist.push({
          value: item.path,
          label: item.title,
        });
      });

      this.setState({ contentlist: mylist });
      // console.log(mylist);
    }

  };

  handleContentChange = (e) => {
    this.setState({ 'content': e.target.value });
  };

  handleEventSelected(event) {
    // console.log("event", event);
    // alert(event.content);
    this.setState({
      openEvent: true,
      clickedEvent: event,
      _id: event._id,
      start: event.start,
      end: event.end,
      title: event.title,
      desc: event.desc,
      repeating: event.repeating,
      service: event.service,
      content: event.content,
      duration: event.duration,
      notes: event.notes
    });
    let mylist = [];
    const value = event.service;
    const name = localStorage.getItem('remoteId');
    if (value == 'Learning') {

      var api = `{"action":"getLearningList","name":"${name}"}`;
      waitForSocketConnection(socket, () => sendMessage(api))
    }

    if (value == 'Quiz') {


      var api = `{"action":"getQuizList","name":"${name}"}`;
      waitForSocketConnection(socket, () => sendMessage(api));
      // AuthService.getQuizList().then(
      //   (response) => {

      //     response.data.quiz.map((item,i) => {
      //         mylist.push({                
      //           value: item,
      //           label: item,
      //         });
      //     });

      //     this.setState({contentlist:mylist});
      //     //console.log(mylist);
      //   },
      //   (error) => {

      //   }
      // )
    }

    if (value == 'Bingo') {

      var api = `{"action":"getBingoList","name":"${name}"}`;
      waitForSocketConnection(socket, () => sendMessage(api));
    }


    if (value == 'Conversation') {
      var api = `{"action":"getWorkFlowList","name":"${name}"}`;
      waitForSocketConnection(socket, () => sendMessage(api));
    }

    if (value === 'Music') {
      console.log("SELECTING MUSIC")
      this.state.songs.map((item, i) => {
        mylist.push({
          value: item.path,
          label: item.title,
        });
      });

      this.setState({ contentlist: mylist });
      // console.log(mylist);
    }

    if (value === 'YouTube') {
      var api = `{"action":"getYouTubeList","name":"${name}"}`;
      waitForSocketConnection(socket, () => sendMessage(api));
    }

    if (value === 'Book') {
      this.state.books.map((item, i) => {
        mylist.push({
          value: item.path,
          label: item.title,
        });
      });

      this.setState({ contentlist: mylist });
      // console.log(mylist);
    }
    // if (value == 'Learning')    
    // {




    //   // AuthService.getLearningData().then(
    //   //   (response) => {

    //   //     response.data.learning.map((item,i) => {
    //   //         mylist.push({                
    //   //           value: item,
    //   //           label: item,
    //   //         });
    //   //     });

    //   //     this.setState({contentlist:mylist});
    //   //     //console.log(mylist);
    //   //   },
    //   //   (error) => {

    //   //   }
    //   // )
    // }

    // if (value == 'Quiz')    
    // {
    //   // AuthService.getQuizList().then(
    //   //   (response) => {

    //   //     response.data.quiz.map((item,i) => {
    //   //         mylist.push({                
    //   //           value: item,
    //   //           label: item,
    //   //         });
    //   //     });

    //   //     this.setState({contentlist:mylist});
    //   //     //console.log(mylist);
    //   //   },
    //   //   (error) => {

    //   //   }
    //   // )
    // }

    // if (value == 'Bingo')    
    // {
    //   // AuthService.getBingoList().then(
    //   //   (response) => {

    //   //     response.data.bingo.map((item,i) => {
    //   //         mylist.push({                
    //   //           value: item,
    //   //           label: item,
    //   //         });
    //   //     });

    //   //     this.setState({contentlist:mylist});
    //   //     //console.log(mylist);
    //   //   },
    //   //   (error) => {

    //   //   }
    //   // )
    // }


    // if (value === 'Conversation')    
    // {
    //   // AuthService.getWorkflowList().then(
    //   //   (response) => {

    //   //     response.data.workflows.map((item,i) => {
    //   //         mylist.push({                
    //   //           value: item,
    //   //           label: item,
    //   //         });
    //   //     });

    //   //     this.setState({contentlist:mylist});
    //   //    // console.log(mylist);
    //   //   },
    //   //   (error) => {

    //   //   }
    //   // )
    // }

    // if (value === 'Music')
    // {
    //   this.state.songs.map((item,i) => {
    //     mylist.push({                
    //       value: item.path,
    //       label: item.title,
    //     });
    //   });

    //   this.setState({contentlist:mylist});
    //  // console.log(mylist);
    // }

    // if (value === 'Book')
    // {
    //   this.state.books.map((item,i) => {
    //     mylist.push({                
    //       value: item.path,
    //       label: item.title,
    //     });
    //   });

    //   this.setState({contentlist:mylist});
    //  // console.log(mylist);
    // }


  }

  setTitle(e) {
    this.setState({ title: e });
  }

  setDuration(e) {
    this.setState({ duration: e });
  }

  setNotes(e) {
    this.setState({ notes: e });
  }

  setDescription(e) {
    this.setState({ desc: e });
  }


  setRepeating(e) {
    this.setState({ repeating: e });
  }
  handleStartTime = (event, date) => {

    this.setState({ start: new Date(moment(date)) });
    // alert(date);
    var enddate = new Date(moment(date).add(60, 'm').toDate());

    this.setState({ end: enddate });
  };

  handleEndTime = (event, date) => {
    this.setState({ end: date });
  };

  // Onclick callback function that pushes new appointment into events array.
  setNewAppointment() {
    const { _id, title, desc, start, end, repeating, service, content, events, clickedEvent, duration, notes } = this.state;
    let appointment = { title, start, end, desc, repeating, service, content, duration, notes };
    console.log(appointment);
    console.log("DURATION ", duration);
    console.log("notes ", notes);
    const name = localStorage.getItem('remoteId');
    let newevents = events.slice();

    newevents.push(appointment);


    var message = `{"action":"setAppointment","event":${JSON.stringify(newevents)}, "name":"${name}" }`;

    waitForSocketConnection(socket, () => { sendMessage(message) })
    // UserService.createAppointment(appointment);

    this.setState({ events: newevents });
  }



  //  Updates Existing Appointments Title and/or Description
  updateEvent() {
    const { _id, title, desc, start, end, repeating, service, content, events, clickedEvent, duration, notes } = this.state;
    console.log("clickedEvent", repeating);
    const index = events.findIndex(event => event === clickedEvent);
    const updatedEvent = events.slice();
    updatedEvent[index]._id = _id;
    updatedEvent[index].title = title;
    updatedEvent[index].desc = desc;
    updatedEvent[index].start = start;
    updatedEvent[index].end = end;
    updatedEvent[index].repeating = repeating;
    updatedEvent[index].service = service;
    updatedEvent[index].content = content;
    updatedEvent[index].duration = duration;
    updatedEvent[index].notes = notes;
    const name = localStorage.getItem('remoteId');
    const newEvents = []
    events.map(p => {
      if (p._id == _id) {
        p.title = title;
        p.desc = desc;
        p.repeating = repeating;
        p.service = service;
        p.content = content;
        p.duration = duration;
        p.notes = notes;
        var newStart = new Date(p.start);
        newStart.setHours((new Date(start)).getHours()); newStart.setMinutes((new Date(start)).getMinutes()); newStart.setSeconds((new Date(start)).getSeconds());

        var newEnd = new Date(p.end);
        newEnd.setHours((new Date(end)).getHours()); newEnd.setMinutes((new Date(end)).getMinutes()); newEnd.setSeconds((new Date(end)).getSeconds());
        p.start = newStart;
        p.end = newEnd;


      }
      newEvents.push(p);
    }

    );

    //localStorage.setItem("cachedEvents", JSON.stringify(updatedEvent));
    //update event in database
    //UserService.updateMyCalendar(JSON.stringify(newEvents));
    var message = `{"action":"setAppointment","event":${JSON.stringify(newEvents)}, "name":"${name}" }`;

    waitForSocketConnection(socket, () => { sendMessage(message) })

    this.setState({
      events: newEvents
    });


  }

  //  filters out specific event that is to be deleted and set that variable to state
  deleteEvent() {
    let updatedEvents = this.state.events.filter(
      event => event["start"] !== this.state.start
    );
    const { _id, title, desc, start, end, repeating, service, content, events, clickedEvent, duration, notes } = this.state;
    // UserService.updateMyCalendar(JSON.stringify(updatedEvents));
    const name = localStorage.getItem('remoteId');
    var message = `{"action":"setAppointment","event":${JSON.stringify(updatedEvents)}, "name":"${name}" }`;

    waitForSocketConnection(socket, () => { sendMessage(message) })
    //alert(_id);
    //localStorage.setItem("cachedEvents", JSON.stringify(updatedEvents));
    this.setState({ events: updatedEvents });

    //delete event from database
  }

  computeTitle = (title) => {
    console.log("COMPUTE TITLE")
    let language = localStorage.getItem("pageLanguage");
    if (language !== "English" && language !== undefined) {
      title = TranslationService.translateString(title, language);
      console.log("TITLE ", title)
    }
    return title
  }


  render() {
    const { classes } = this.props;
    //console.log("render()");
    const eventActions = [
      <Button
        label="Cancel"
        primary={false}
        keyboardFocused={true}
        onClick={this.handleClose}
      />,
      <Button
        label="Delete"
        secondary={true}
        keyboardFocused={true}
        onClick={() => {
          this.deleteEvent(); this.handleClose();
        }}
      />,
      <Button
        label="Confirm Edit"
        primary={true}
        keyboardFocused={true}
        onClick={this.handleClose}
        onClick={() => {
          this.updateEvent(); this.handleClose();
        }}
      />
    ];
    const appointmentActions = [
      <Button label="Cancel" secondary={true} onClick={this.handleClose} />,
      <Button
        label="Submit"
        primary={true}
        keyboardFocused={true}
        onClick={() => {
          this.setNewAppointment(); this.handleClose();
        }}
      />
    ];

    const handleEventToday = (e) => {
      console.log("Event Today")
      var api = `{"api":"todaySchedule", "action":"get","name": "${name}"}`;
      waitForSocketConnection(socket, () => sendMessage(api))
    }

    return (
      <div className="">
        <TitleBar title={this.computeTitle("Calendar")} parentNode={"/service-scheduling"} />
        <Button size="small" type="primary" variant="outlined" value="add" onClick={handleEventToday}>Today's Event</Button>
        <MuiThemeProvider theme={theme}>
          <div id="Calendar" className="App">
            {/* react-big-calendar library utilized to render calendar*/}
            <Calendar
              localizer={localizer}
              events={this.state.events}
              views={["week", "day"]}
              timeslots={2}
              defaultView="week"
              defaultDate={new Date()}
              selectable={true}
              onSelectEvent={event => this.handleEventSelected(event)}
              onSelectSlot={slotInfo => this.handleSlotSelected(slotInfo)}
              // components={{
              //   toolbar: CustomToolbar
              // }}
              onNavigate={date => {
                var firstday = moment(date).startOf('week').toDate();
                var lastday = moment(date).endOf('week').toDate();

                // this.setState({
                //   'firstday':firstday,
                //   'lastday':lastday
                // });
              }}
            />

            {/* Material-ui Modal for booking new appointment */}
            <Dialog className="calendar-popup"
              open={this.state.openSlot}
              onClose={this.handleClose}
            >
              <DialogTitle id="form-dialog-title" onClose={this.handleClose}>{`Schedule an activity on - ${moment(this.state.start).format("MMMM Do YYYY")}`}</DialogTitle>
              <DialogContent dividers>
                <TextField
                  label="Title"
                  inputProps={{
                    maxLength: 200,
                  }}
                  onChange={e => {
                    this.setTitle(e.target.value);
                  }}
                />
                {/* <br />
          <TextField
            label="Description"
            onChange={e => {
              this.setDescription(e.target.value);
            }}
          /> */}
                <br />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    format="yyyy/MM/dd hh:mm a"
                    label="Start Time"
                    minutesStep={1}
                    value={this.state.start}
                    onChange={this.handleStartTime}
                  />
                  {/* <KeyboardTimePicker
            format="yyyy/MM/dd hh:mm a"
            label="End Time"
            minutesStep={5}
            value={this.state.end}
            onChange={this.handleEndTime}
          /> */}
                </MuiPickersUtilsProvider>

                <br />

                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="service-label">Service</InputLabel>
                  <Select defaultValue={this.state.service} value={this.state.service} id="select-service" onChange={this.handleChange}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem key={'music'} value={'Music'}>Music</MenuItem>
                    <MenuItem key={'book'} value={'Book'}>Book</MenuItem>
                    <MenuItem key={'learning'} value={'Learning'}>Learning</MenuItem>
                    <MenuItem key={'quiz'} value={'Quiz'}>Quiz</MenuItem>
                    <MenuItem key={'game'} value={'Game'}>Game</MenuItem>
                    <MenuItem key={'conversation'} value={'Conversation'}>Conversation</MenuItem>
                    <MenuItem key={'bingo'} value={'Bingo'}>Bingo</MenuItem>
                    <MenuItem key={'youtube'} value={'YouTube'}>YouTube</MenuItem>
                    <MenuItem key={'videos'} value={'Videos'}>Videos</MenuItem>
                    <MenuItem key={'TechnologySupport'} value={'TechnologySupport'}>Technology Support</MenuItem>
                    <MenuItem key={'TVChannel'} value={'TVChannel'}>TV Channel</MenuItem>
                  </Select>

                </FormControl>


                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="content-label">Content</InputLabel>
                  <Select defaultValue={this.state.content} value={this.state.content} id="select-content" onChange={this.handleContentChange}>
                    {this.state.contentlist.map((categoria, i) => (
                      <MenuItem key={i} value={categoria.value}>{categoria.label}</MenuItem>
                    ))}
                  </Select>

                </FormControl>

                <br />
                <TextField
                  label="Repeat"
                  multiline={false}
                  defaultValue={this.state.repeating}
                  onChange={e => {
                    this.setRepeating(e.target.value);
                  }}
                />
                <TextField
                  label="Duration (in mins)"
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 100, min: 1
                    }
                  }}
                  defaultValue={5}
                  onChange={e => {
                    this.setDuration(e.target.value);
                  }}
                />
                <TextField
                  label="Notes"
                  inputProps={{
                    maxLength: 200,
                  }}
                  onChange={e => {
                    this.setNotes(e.target.value);
                  }}
                />

                <FormControl fullWidth>
                  <InputLabel id="language-select-label">Language</InputLabel>
                  <Select
                    labelId="language-select-label"
                    id="language-select"
                    value={this.state.language}
                    onChange={e => { this.setState({language: e.target.value})}}
                    label="Language"
                  >
                    <MenuItem value="russian">Russian</MenuItem>
                    <MenuItem value="english">English</MenuItem>
                    <MenuItem value="japanese">Japanese</MenuItem>
                    <MenuItem value="serbian">Serbian</MenuItem>
                    <MenuItem value="cantonese">Cantonese</MenuItem>
                    <MenuItem value="korean">Korean</MenuItem>
                    <MenuItem value="arabic">Arabic</MenuItem>
                    <MenuItem value="hindi">Hindi</MenuItem>
                    <MenuItem value="polish">Polish</MenuItem>
                    <MenuItem value="sinhala">Sinhala</MenuItem>
                    <MenuItem value="filipino">Filipino</MenuItem>
                    <MenuItem value="macedonian">Macedonian</MenuItem>
                  </Select>
                </FormControl>

              </DialogContent>

              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={() => {
                  this.setNewAppointment(); this.handleClose();
                }} color="primary">
                  Submit
                </Button>
              </DialogActions>


            </Dialog>

            {/* Material-ui Modal for Existing Event */}
            <Dialog
              className="calendar-popup"


              // modal={false}
              open={this.state.openEvent}
              onClose={this.handleClose}
            >
              <DialogTitle id="form-dialog-title" onClose={this.handleClose}>{`View/Edit appointment of - ${moment(this.state.start).format("MMMM Do YYYY")}`}</DialogTitle>
              <DialogContent dividers>
                <TextField
                  label="Title"
                  defaultValue={this.state.title}
                  inputProps={{
                    maxLength: 200,
                  }}
                  onChange={e => {
                    this.setTitle(e.target.value);
                  }}
                />
                <br />
                {/* <TextField
            label="Description"
            defaultValue={this.state.desc}
            onChange={e => {
              this.setDescription(e.target.value);
            }}
          />
          <br /> */}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    format="yyyy/MM/dd hh:mm a"
                    label="Start Time"
                    minutesStep={5}
                    value={this.state.start}
                    onChange={this.handleStartTime}
                  />
                  {/* <KeyboardTimePicker
            format="yyyy/MM/dd hh:mm a"
            label="End Time"
            minutesStep={5}
            value={this.state.end}
            onChange={this.handleEndTime}
          /> */}
                </MuiPickersUtilsProvider>
                <br />

                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="service-label">Service</InputLabel>
                  <Select defaultValue={this.state.service} value={this.state.service} id="select-service" onChange={this.handleChange}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem key={'music'} value={'Music'}>Music</MenuItem>
                    <MenuItem key={'book'} value={'Book'}>Book</MenuItem>
                    <MenuItem key={'learning'} value={'Learning'}>Learning</MenuItem>
                    <MenuItem key={'quiz'} value={'Quiz'}>Quiz</MenuItem>
                    <MenuItem key={'game'} value={'Game'}>Game</MenuItem>
                    <MenuItem key={'conversation'} value={'Conversation'}>Conversation</MenuItem>
                    <MenuItem key={'bingo'} value={'Bingo'}>Bingo</MenuItem>
                    <MenuItem key={'youtube'} value={'YouTube'}>YouTube</MenuItem>
                    <MenuItem key={'videos'} value={'Videos'}>Videos</MenuItem>
                    <MenuItem key={'TechnologySupport'} value={'TechnologySupport'}>Technology Support</MenuItem>
                    <MenuItem key={'TVChannel'} value={'TVChannel'}>TV Channel</MenuItem>
                  </Select>

                </FormControl>


                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="content-label">Content</InputLabel>
                  <Select defaultValue={this.state.content} value={this.state.content} id="select-content" onChange={this.handleContentChange}>

                    {this.state.contentlist.map((categoria, i) => (
                      <MenuItem key={i} value={categoria.value}>{categoria.label}</MenuItem>
                    ))}
                  </Select>

                </FormControl>


                <br />
                <TextField
                  label="Repeat"
                  multiline={false}
                  defaultValue={this.state.repeating}
                  onChange={e => {
                    this.setRepeating(e.target.value);
                  }}
                />
                <TextField
                  label="Duration (in mins)"
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 100, min: 1
                    }
                  }}
                  value={this.state.duration}
                  onChange={e => {
                    this.setDuration(e.target.value);
                  }}
                />
                <TextField
                  label="Notes"
                  inputProps={{
                    maxLength: 200,
                  }}
                  value={this.state.notes}
                  onChange={e => {
                    this.setNotes(e.target.value);
                  }}
                />
              </DialogContent>

              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>

                <Button onClick={() => {
                  this.deleteEvent(); this.handleClose();
                }} color="primary">
                  Delete
                </Button>

                <Button onClick={() => {
                  this.updateEvent(); this.handleClose();
                }} color="primary">
                  Confirm Edit
                </Button>
              </DialogActions>


            </Dialog>
          </div>
        </MuiThemeProvider>
      </div>
    );

  }
}

export default withStyles(styles)(MyCalendar);
